
export const expressUserContactsMixin = {
  computed: {


    getUserContacts() {
      if(this.getUserAlternativeContact(this.getCurrentUser)) {
        return this.getUserAlternativeContact(this.getCurrentUser)
      }

      return this.getUserContactDataFromUser(this.getCurrentUser)
    },

    getUserContactsBySelectedUser() {
      if(this.getUserAlternativeContact(this.ex.express.user)) {
        return this.getUserAlternativeContact(this.ex.express.user)
      }

      return this.getUserContactDataFromUser(this.ex.express.user)
    },



    getExpressUserAddress() {
      if(this.ex.express.user?.contacts){
        return this.ex.express.user.contacts[0].address
      }
      return this.ex.express.user?.user_personal_contact.address
    },
    getExpressUserCity() {
      if(this.ex.express.user?.contacts){
        return this.ex.express.user.contacts[0].city
      }
      return this.ex.express.user?.user_personal_contact.city
    },
    getExpressUserRegion() {
      if(this.ex.express.user?.contacts){
        return this.ex.express.user.contacts[0].region.name
      }
      return this.ex.express.user?.user_personal_contact.region.name
    },
    getExpressUserCountry() {
      if(this.ex.express.user?.contacts){
        return this.ex.express.user.contacts[0].country.name
      }
      return this.ex.express.user?.user_personal_contact.country.name
    },
    getExpressUserZip() {
      if(this.ex.express.user?.contacts){
        return this.ex.express.user.contacts[0].zip
      }
      return this.ex.express.user?.user_personal_contact.zip
    },
  },

  methods: {

  },
}
